import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import FeaturedRoomsList from "../components/Features/FeaturedRoomsList"
import LatestPosts from "../components/Post/LatestPosts"

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule subTitle="Home of the infamous Full Moon Party in Koh Phangan" />
        <FeaturedRoomsList
          title="Featured Rooms from Eclipse"
          description="Check out our featured rooms to find the accommodation that best suits your needs"
        />
        <BasicTextModule
          title="Great location - 1 minute walk from Haad Rin Beach,  home of the famous Full Moon party."
          content={
            <p>
              Eclipse Hostel & Bar is open since December 2016. It has a great
              location - only 1 minute walk from Haad Rin Beach where the famous
              Full Moon party takes place.
              <br />
              <br />
              The hostel is easily accessible, only a 5-minute walk from Haad
              Rin Pier and a scenic 25-minute drive from ThongSala pier through
              the mountains. Despite its proximity to the Full Moon Party beach
              (just 100 meters away), the hostel offers a peaceful retreat,
              ensuring a good night's sleep in cozy dorm rooms or private rooms.
              Both options feature air conditioning, fast WiFi, and shared
              bathrooms with hot showers.
              <br />
              <br />
              For relaxation, the hostel provides a shared lounge where guests
              can unwind with a beer. The friendly staff is ready to assist with
              information about island tours, party tickets, and tickets to your
              next destination.
              <br />
              <br />
              Numerous local restaurants offering amazing Thai and international
              cuisine, as well as clothing shops and beautiful beaches, are
              within walking distance from the hostel.
            </p>
          }
          link="/rooms"
          linkText="View Rooms"
        />
        <BasicTextModule
          title="TM30"
          content={
            <>
              Per Thai immigration law, we submit{" "}
              <a
                href="https://tm30.io/"
                title="Learn more about TM30 requirements"
                target="_blank"
              >
                TM30 forms
              </a>{" "}
              for each guest. Copies can be provided on request free of charge.
            </>
          }
        />
        {/* <LatestPosts
          title="The Latest from Barcadia"
          introduction="Cras scelerisque, tellus sed gravida tincidunt, velit tellus blandit justo, nec viverra augue erat in erat. Maecenas iaculis sed purus non fringilla."
        /> */}
      </Layout>
    </>
  )
}

export default Index
